<template>
  <div>
    <a-card title="调拨单详情">
      <a-button slot="extra" type="primary" style="margin-right: 8px" ghost v-print="'#printContent'">
        <a-icon type="printer" />打印</a-button
      >
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section id="printContent">
        <a-spin :spinning="loading">
          <img id="barcode" style="float: right" />
          <a-descriptions bordered>
            <a-descriptions-item label="调拨编号">
              {{ info.number }}
            </a-descriptions-item>
            <a-descriptions-item label="出库仓库">
              {{ info.out_warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="入库仓库">
              {{ info.in_warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item label="经手人">
              {{ info.handler_name }}
            </a-descriptions-item>
            <a-descriptions-item label="处理日期">
              {{ info.handle_time }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ info.remark }}
            </a-descriptions-item>
          </a-descriptions>
          <a-divider orientation="left" style="margin-top: 30px">物料信息</a-divider>
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="info.stock_transfer_goods_items"
            :pagination="false"
          />
        </a-spin>
      </section>
    </a-card>
  </div>
</template>

<script>
import { stockTransferDetail } from "@/api/warehouse";
import JsBarcode from "jsbarcode";

export default {
  data() {
    return {
      loading: false,
      materialLoading: false,
      receiptOrder: undefined,
      info: {},
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "调拨数量",
          dataIndex: "stock_transfer_quantity",
          key: "stock_transfer_quantity",
          width: 120,
        },
      ],
      columnsAccount: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "结算账户",
          dataIndex: "account_name",
          key: "account_name",
          width: 200,
        },
        {
          title: "付款金额",
          dataIndex: "payment_amount",
          key: "payment_amount",
          width: 200,
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    getJsBarcode(number) {
      JsBarcode("#barcode", number, {
        lineColor: "#000",
        width: 2,
        height: 40,
        displayValue: true,
      });
    },
    initData() {
      this.loading = true;
      stockTransferDetail({ id: this.$route.query.id })
        .then((data) => {
          this.info = data;
          this.info.stock_transfer_goods_items = [
            ...this.info.stock_transfer_goods_items,
            {
              id: "-1",
              isTotal: true,
              stock_transfer_quantity: this.info.total_quantity,
            },
          ];
          this.getJsBarcode(data.number);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
